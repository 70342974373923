#notfound {
    position: relative;
    height: 100vh;
    background-color: #fafbfd;
  }
  
  #notfound .notfound {
    position: absolute !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 520px;
    width: 100%;
    text-align: center;
  }
  
  #notfound .notfound h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 86px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
    color: #333333;
  }
  
  #notfound .notfound h2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 26px;
    margin: 0;
    font-weight: 700;
    color: #333333;
  }
  
  #notfound .notfound a {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #18e06f;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 480px) {
    #notfound .notfound h1 {
      font-size: 68px;
    }
  
    #notfound .notfound h2 {
      font-size: 18px;
    }
  }